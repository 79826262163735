<template>
  <div class="chat-ai-con">
    <div class="chat-ai-content" :class="{'show-info': info!==''}">
      <div class="chat-ai-container">
        <div id="chat_container">
          <div class="chat-header" :class="{'minimize':isAsk}">
            <div class="container">
              <div class="chat-header-content">
                <div class="guide-ai-img">
                  <img src="/ui-new/images/question-ai.png" alt="qs-image-col">
                </div>
                <div class="guide-ai">
                  <p>CHAT GPT</p>
                  <p v-if="user == null">
                    <!--                    <span>Miễn phí lần đầu <span>02</span> câu hỏi&nbsp;&emsp;</span><br/>-->
                    <span>Đăng ký gói ngay để nhận <span>10</span> câu hỏi/ngày</span><br/>
                  </p>
                  <p v-else>
                    <span v-show="remainSubQuestion <= 0 || remainFreeQuestion !==0">Còn <span>{{ remainFreeQuestion }}</span> câu hỏi miễn phí</span>
                    <span v-show="remainFreeQuestion === 0 && remainSubQuestion > 0">Còn <span>{{ remainSubQuestion }}</span> câu hỏi hôm nay</span>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <MessageCont :wrapper="wrapper" :old-conversation="oldConversation"/>

          <div class="chat-additional-btn">
            <div :class="{'active':info=='trick'}" @click="showInfo('trick')">
              <img src="@/assets/icons/ai/trick.svg">
            </div>
            <div :class="{'active':info=='note'}" @click="showInfo('note')">
              <img src="@/assets/icons/ai/note.svg">
            </div>
          </div>
        </div>
        <div class="chat-form-search-container">
          <div class="container ">
            <form @submit.prevent="submitQuestion" class="form-search-ai">
              <textarea
                ref="searchBox"
                rows="1"
                cols="1"
                placeholder="Nhập câu hỏi!"
                v-model="question"
                style="overflow: hidden; resize: none;"
                class="text-search-ai"
                @keydown="handleKeyDown"
              ></textarea>
              <button
                type="submit"
              >
                <!--                :class="{'disabled':myInterval !== null || (remainSubQuestion <= 0 && remainFreeQuestion ===0)}"-->
                <!--                :disabled="myInterval !== null || (remainSubQuestion <= 0 && remainFreeQuestion ===0)"-->
                <img src="@/assets/icons/ai/send-1.svg" alt="send"/>
              </button>
            </form>
          </div>
        </div>
      </div>
      <div class="chat-addtion-info">
        <div class="close" @click="hideInfo()"><img src="@/assets/icons/ai/close.svg"/></div>
        <Note v-if="info=='note'"/>
        <Trick v-if="info=='trick'"/>
      </div>
    </div>
    <UpdateMobile/>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import {uuid} from 'vue-uuid'
import {SearchAI, GetAnswers, GetRemainQuestion, GetHistory} from '~/common/api/openai-api'
import Note from '~/components/ai/Note.vue'
import Trick from '~/components/ai/Trick.vue'
import MessageCont from '~/components/ai/MessageCont.vue'
import UpdateMobile from '~/components/mlearn/modal/update-mobile.vue'

export default {
  components: {
    UpdateMobile,
    MessageCont,
    Trick,
    Note
  },
  data () {
    return {
      wrapper: [{
        isAi: true,
        value: 'Xin chào, tôi là Chat GPT của Vilearn, tôi có thể giúp gì được cho bạn không?',
        created_at: 'Hôm nay',
        isDefault: true
      }],
      oldConversation: [],
      currentConversation: null,
      isAsk: true,
      loading: false,
      question: '',
      answer: '',
      myInterval: null,
      info: '',
      remainFreeQuestion: 0,
      remainSubQuestion: 0
    }
  },
  computed: {
    ...mapState([
      'user'
    ])
  },
  mounted () {
    this.$el.addEventListener('resize', this.setHeight)
    this.setHeight()
    if (this.user?.uuid) {
      this.getRemainQuestion()
      this.getHistory()
    } else {
      this.isAsk = false
    }
  },
  destroyed () {
    this.$el.removeEventListener('resize', this.setHeight)
  },
  methods: {
    submitQuestion () {
      if (!this.user?.uuid) {
        this.$bvModal.show('modal-login')
        return
      }
      if (this.remainSubQuestion === -1 && !this.remainFreeQuestion) {
        this.$bvModal.show('modal-request-sub')
        return
      } else if (!this.remainSubQuestion && !this.remainFreeQuestion) {
        this.$bvModal.show('no-more-question')
        return
      }
      if (this.question === '') {
        return
      }
      try {
        this.loading = true
        this.wrapper.push({
          isAi: false,
          value: this.question?.trim(),
          created_at: this.$moment().format('HH:mm')
        })
        this.isAsk = true
        this.wrapper.push({
          isAi: true,
          value: 'Đang trả lời....'
        })
        const randomNumber = Math.random() * 100
        const today = new Date()
        const millisecondsSinceEpoch = today.getTime()
        const seconds = Math.floor(millisecondsSinceEpoch)
        const data = {
          question: this.question?.trim(),
          requestId: uuid.v4() + '_' + randomNumber + '_' + seconds,
          conversationId: this.currentConversation
        }
        // console.log('data : ', data)
        SearchAI(data).then((res) => {
          this.question = ''
          if (res.status === 0) {
            const dataRev = {
              question: res.question,
              requestId: res.requestId
            }
            this.myInterval = setInterval(() => {
              GetAnswers(dataRev).then((res2) => {
                if (res2.status === 0) {
                  clearInterval(this.myInterval)
                  this.myInterval = null
                  this.currentConversation = res2.conversationId
                  // Kiểm Tra anwser
                  // this.question = res2.answer.trim()
                  this.wrapper.pop()
                  this.wrapper.push({
                    isAi: true,
                    value: res2.answer.trim()
                  })
                  this.getRemainQuestion()
                }
              })
            }, 1000)
          } else if (res.status === 203) {
            clearInterval(this.myInterval)
            this.myInterval = null
            this.wrapper.pop()
            this.$bvModal.show('modal-redirect-sub')
            this.$notify({
              type: 'warning',
              message: res.message
            })
          } else {
            clearInterval(this.myInterval)
            this.myInterval = null
            this.wrapper.pop()
            return this.$notify({
              title: 'Lỗi',
              message: res.message,
              type: 'error'
            })
          }
        })
      } catch (error) {
        return this.$notify({
          title: 'Lỗi',
          message: error.message,
          type: 'error'
        })
      } finally {
        this.loading = false
        this.question = ''
      }
    },
    getRemainQuestion () {
      GetRemainQuestion().then((res) => {
        if (res.status !== 0) {
          this.$notify({
            title: 'Lỗi',
            message: res.message,
            type: 'error'
          })
        } else if (res.subQuestion === -1 && !res.freeQuestion) {
          this.$bvModal.show('modal-request-sub')
        } else if (!res.subQuestion && !res.freeQuestion) {
          this.$bvModal.show('no-more-question')
        }
        this.remainSubQuestion = res.subQuestion
        this.remainFreeQuestion = res.freeQuestion
      }).catch((err) => {
        this.$notify({
          title: 'Lỗi',
          message: err.message,
          type: 'error'
        })
      })
    },
    getHistory () {
      console.log(this.user?.uuid)
      if (!this.user?.uuid) {
        return
      }
      GetHistory().then((res) => {
        if (res.status !== 200) {
          this.$notify({
            title: 'Lỗi',
            message: res.message,
            type: 'error'
          })
          return
        }
        if (res.list?.length > 0) {
          this.isAsk = true
          if (res.list?.some(it => this.$moment(it.created_at).isSame(this.$moment(), 'day'))) {
            this.wrapper = []
          }
          if (this.wrapper.length > 0) {
            this.wrapper[0].value = 'Xin chào! Hôm nay bạn cần Chat GPT hỗ trợ về điều gì?'
          }
          this.oldConversation = res.list
        }
      }).catch((err) => {
        this.$notify({
          title: 'Lỗi',
          message: err.message,
          type: 'error'
        })
      })
    },
    showInfo (type) {
      this.info = type
    },
    hideInfo () {
      this.info = ''
    },
    setHeight () {
      document.documentElement.style.setProperty('--vh', `${window.innerHeight}px`)
    },
    handleKeyDown (e) {
      if (e.key === 'Enter') {
        if (!e.altKey) {
          e.preventDefault()
          if (!this.user?.uuid) {
            this.$bvModal.show('modal-login')
            return
          }
          if (this.remainFreeQuestion > 0 || this.remainSubQuestion > 0) {
            this.submitQuestion()
          } else if (this.remainSubQuestion === -1 && !this.remainFreeQuestion) {
            this.$bvModal.show('modal-request-sub')
          } else if (!this.remainSubQuestion && !this.remainFreeQuestion) {
            this.$bvModal.show('no-more-question')
          }
        } else {
          this.$refs.searchBox.value += '\r\n'
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.chat-ai-con {
  margin-top: -10px;
  @media (max-width: 1366px) {
    margin-top: -10px;
  }
  @media (max-width: 992px) {
    margin-top: -25px;
  }
  @media (max-width: 576px) {
    margin-top: -4px;
  }
}

.disabled {
  opacity: 0.4;
}

.form-search-ai {
  border: 2px solid #005FAF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  background: #fff;
  padding: 0 14px;

  .text-search-ai {
    color: #1b1c20;
  }
}

.info-header {
  margin-top: 76px;
  text-align: center;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 29px;
  color: #373535;
  border-bottom: 2px solid #9FDBF9;;
}

.close {
  position: absolute;
  top: 20px;
  left: 10px;
  cursor: pointer;

  img {
    width: 40px;
  }
}
</style>
